import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
} from '../styles';

const StyledStatisticCard = styled.article`
  padding: 20px 20px 30px;
  color: ${standardColours.white};
  background-color: ${brandColours.slate[500]};

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-bottom: 40px;
  `}
`;

const StyledHeading = styled.h3`
  ${brandFonts.futuraPTDemi()};
  ${fontSize(26)};
  line-height: 1.1;

  ${minBreakpointQuery.small`
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}

  span {
    display: block;
    margin-bottom: 4px;
    color: ${brandColours.clay[300]};
    ${brandFonts.futuraPTBold()};
    ${fluidFontSize(
      '38px',
      '50px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  }
`;

const StyledText = styled.p`
  margin-top: 6px;

  ${minBreakpointQuery.small`
    margin-top: 8px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 10px;
    ${fontSize(20)};
  `}
`;

const StatisticCard = ({ heading, text }) => (
  <StyledStatisticCard>
    <StyledHeading
      dangerouslySetInnerHTML={{
        __html: heading.replace(/^(\w+)/, '<span>$1</span>'),
      }}
    />
    <StyledText>{text}</StyledText>
  </StyledStatisticCard>
);

export default StatisticCard;
