import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Slider } from './ui';

const StyledImageSlider = styled.section`
  ${({ isHomePage, isContactPage }) => {
    if (isHomePage || isContactPage) {
      return css`
        position: relative;
        z-index: 1;
        display: inline-block;
        margin-top: -200px;
        width: 100%;
      `;
    } else {
      return css`
        ${sectionMargins(undefined, '60px')};
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  max-width: 970px;
`;

const StyledInner = styled.div`
  position: relative;
`;

const StyledSlider = styled(Slider)`
  .slick-arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 50%;
    outline: none;

    &:before {
      content: none;
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }

  .slick-slide {
    &.slick-active {
      img {
        opacity: 1 !important;
      }
    }
  }
`;

const StyledImage = styled(GatsbyImage)`
  min-height: 520px;
`;

const StyledLogo = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 100px;
  z-index: 1;

  ${minBreakpointQuery.tiny`
    right: 20px;
    bottom: 20px;
  `}

  ${minBreakpointQuery.small`
    width: 120px;
  `}
`;

const ImageSlider = ({ items, logo, isHomePage, isContactPage }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
  };

  return (
    <StyledImageSlider isHomePage={isHomePage} isContactPage={isContactPage}>
      <StyledContainer>
        <StyledInner>
          <StyledSlider numSlides={items.length} {...sliderOptions}>
            {items.map(({ gatsbyImageData, alt }, i) => (
              <StyledImage
                key={`slider-image-${i}`}
                image={gatsbyImageData}
                alt={alt}
                loading="eager"
              />
            ))}
          </StyledSlider>
          {logo && (
            <StyledLogo>
              <GatsbyImage image={logo.gatsbyImageData} alt={logo.alt} />
            </StyledLogo>
          )}
        </StyledInner>
      </StyledContainer>
    </StyledImageSlider>
  );
};

export default ImageSlider;

export const ImageSliderImagesFragment = graphql`
  fragment ImageSliderImagesFragment on DatoCmsFileField {
    gatsbyImageData(width: 930, height: 620)
    alt
  }
`;

export const ImageSliderLogoFragment = graphql`
  fragment ImageSliderLogoFragment on DatoCmsFileField {
    gatsbyImageData(width: 120)
    alt
  }
`;
