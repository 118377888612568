import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, brandFonts, fontSize } from '../styles';

const StyledImageContentCard = styled.article`
  margin-bottom: 30px;
`;

const StyledImage = styled(GatsbyImage)`
  height: 360px;

  ${minBreakpointQuery.medium`
    height: 390px;
  `}
`;

const StyledHeading = styled.h3`
  margin-top: 15px;
  ${brandFonts.futuraPTDemi()};
  ${fontSize(20)};

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}
`;

const StyledText = styled.p`
  margin-top: 6px;

  ${minBreakpointQuery.small`
    margin-top: 8px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 10px;
    ${fontSize(20)};
  `}
`;

const ImageContentCard = ({
  image: { gatsbyImageData, alt },
  heading,
  text,
  ...props
}) => (
  <StyledImageContentCard {...props}>
    <StyledImage image={gatsbyImageData} alt={alt} />
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
  </StyledImageContentCard>
);

export default ImageContentCard;
