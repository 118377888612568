import React from 'react';
import { graphql } from 'gatsby';
import Accordion from './Accordion';
import Brands from './Brands';
import CtaCards from './CtaCards';
import CtaCard from './CtaCard';
import CtaStrip from './CtaStrip';
import ContactFormInformation from './ContactFormInformation';
import ContainedImage from './ContainedImage';
import Content from './Content';
import ContentCardsCarousel from './ContentCardsCarousel';
import ContentCards from './ContentCards';
import ExternalVideo from './ExternalVideo';
import FeaturedInformationCards from './FeaturedInformationCards';
import FeaturedLogos from './FeaturedLogos';
import FullWidthImage from './FullWidthImage';
import ImageContentCards from './ImageContentCards';
import ImageTestimonials from './ImageTestimonials';
import ImageCarousel from './ImageCarousel';
import ImageSlider from './ImageSlider';
import InternalVideo from './InternalVideo';
import Quote from './Quote';
import Statistics from './Statistics';
import Tabs from './Tabs';
import TeamProfiles from './TeamProfiles';
import Testimonials from './Testimonials';

const ModularBlocks = ({ items, altTheme, isHomePage }) =>
  items.map(item => (
    <React.Fragment key={item.id}>
      {item.model.apiKey === 'accordion_modular_block' && (
        <Accordion
          heading={item.instance.heading}
          items={item.instance.accordionItems}
        />
      )}
      {item.model.apiKey === 'brands_modular_block' && (
        <Brands items={item.brands} />
      )}
      {item.model.apiKey === 'cta_cards_modular_block' && (
        <CtaCards>
          {item.instance.ctaCards.map(
            ({ id, heading, text, linkPage, linkText }) => (
              <CtaCard
                key={id}
                heading={heading}
                text={text}
                link={{ slug: linkPage.slug, text: linkText, data: linkPage }}
              />
            )
          )}
        </CtaCards>
      )}
      {item.model.apiKey === 'cta_strip_modular_block' && (
        <CtaStrip
          overline={item.overline}
          heading={item.heading}
          link={{
            page: item.linkPage,
            text: item.linkText,
          }}
          backgroundColour={item.backgroundColour}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'contact_form_information_modular_block' && (
        <ContactFormInformation
          heading={item.heading}
          text={item.text}
          image={item.image}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'contained_image_modular_block' && (
        <ContainedImage image={item.image} />
      )}
      {item.model.apiKey === 'content_modular_block' && (
        <Content
          html={item.content}
          contain={item.contain}
          twoColumns={item.twoColumns}
          highlight={item.highlight}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'content_cards_carousel_modular_block' && (
        <ContentCardsCarousel
          heading={item.instance.heading}
          items={item.instance.contentCards}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'content_cards_modular_block' && (
        <ContentCards
          heading={item.instance.heading}
          items={item.instance.contentCards}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'external_video_modular_block' && (
        <ExternalVideo video={item.externalVideo} />
      )}
      {item.model.apiKey === 'featured_information_cards_modular_block' && (
        <FeaturedInformationCards
          testimonial={{
            image: item.instance.testimonialImage,
            quote: item.instance.testimonialQuote,
            name: item.instance.testimonialName,
            role: item.instance.testimonialRole,
          }}
          content={{
            heading: item.instance.contentHeading,
            text: item.instance.contentText,
          }}
        />
      )}
      {item.model.apiKey === 'featured_logos_modular_block' && (
        <FeaturedLogos items={item.items} />
      )}
      {item.model.apiKey === 'full_width_image_modular_block' && (
        <FullWidthImage image={item.image} />
      )}
      {item.model.apiKey === 'image_content_cards_modular_block' && (
        <ImageContentCards
          heading={item.instance.heading}
          items={item.instance.imageContentCards}
          altTheme={altTheme}
          isHomePage={isHomePage}
        />
      )}
      {item.model.apiKey === 'image_testimonials_modular_block' && (
        <ImageTestimonials
          heading={item.heading}
          image={item.image}
          items={item.testimonials.items}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'image_carousel_modular_block' && (
        <ImageCarousel
          items={item.images}
          backgroundColours={{
            top: item.topBackgroundColour && item.topBackgroundColour.hex,
            bottom:
              item.bottomBackgroundColour && item.bottomBackgroundColour.hex,
          }}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'image_slider_modular_block' && (
        <ImageSlider items={item.images} />
      )}
      {item.model.apiKey === 'internal_video_modular_block' && (
        <InternalVideo video={item.internalVideo} />
      )}
      {item.model.apiKey === 'quote_modular_block' && (
        <Quote text={item.quote} />
      )}
      {item.model.apiKey === 'statistics_modular_block' && (
        <Statistics
          heading={item.instance.heading}
          items={item.instance.statistics}
          isHomePage={isHomePage}
        />
      )}
      {item.model.apiKey === 'tabs_modular_block' && (
        <Tabs
          heading={item.instance.heading}
          items={item.instance.tabs}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'team_profiles_modular_block' && (
        <TeamProfiles
          heading={item.heading}
          items={item.teamProfiles}
          altTheme={altTheme}
        />
      )}
      {item.model.apiKey === 'testimonials_modular_block' && (
        <Testimonials items={item.instance.testimonials} altTheme={altTheme} />
      )}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      accordionItems {
        id
        heading
        content
      }
    }
  }

  fragment BrandsModularBlockFragment on DatoCmsBrandsModularBlock {
    id
    model {
      apiKey
    }
    brands {
      name
      lightLogo {
        url
        alt
      }
      darkLogo {
        url
        alt
      }
      websiteUrl
      description
      images {
        ...ImageCarouselImagesFragment
      }
    }
  }

  fragment CtaCardsModularBlockFragment on DatoCmsCtaCardsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      ctaCards {
        id
        heading
        text
        linkPage {
          ...LinkFragment
        }
        linkText
      }
    }
  }

  fragment CtaStripModularBlockFragment on DatoCmsCtaStripModularBlock {
    id
    model {
      apiKey
    }
    overline
    heading
    linkPage {
      ...LinkFragment
    }
    linkText
    backgroundColour {
      hex
    }
  }

  fragment ContactFormInformationModularBlockFragment on DatoCmsContactFormInformationModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    image {
      ...ContactFormInformationImageFragment
    }
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1140)
      alt
    }
  }

  fragment ContentCardsCarouselModularBlockFragment on DatoCmsContentCardsCarouselModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      contentCards {
        id
        heading
        content
        label
      }
    }
  }

  fragment ContentCardsModularBlockFragment on DatoCmsContentCardsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      contentCards {
        id
        content
        heading
      }
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    highlight
    contain
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedInformationCardsModularBlockFragment on DatoCmsFeaturedInformationCardsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      testimonialImage {
        gatsbyImageData(width: 590, height: 590)
        alt
      }
      testimonialQuote
      testimonialName
      testimonialRole
      contentHeading
      contentText
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    items {
      url
      alt
    }
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 2000, layout: FULL_WIDTH)
      alt
    }
  }

  fragment ImageContentCardsModularBlockFragment on DatoCmsImageContentCardsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      imageContentCards {
        id
        image {
          gatsbyImageData(width: 730, height: 730, layout: FULL_WIDTH)
          alt
        }
        heading
        text
      }
    }
  }

  fragment ImageTestimonialsModularBlockFragment on DatoCmsImageTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    heading
    image {
      url
      alt
    }
    testimonials {
      items: testimonials {
        id
        quote
        name
        role
      }
    }
  }

  fragment ImageCarouselModularBlockFragment on DatoCmsImageCarouselModularBlock {
    id
    model {
      apiKey
    }
    images {
      ...ImageCarouselImagesFragment
    }
    topBackgroundColour {
      hex
    }
    bottomBackgroundColour {
      hex
    }
  }

  fragment ImageSliderModularBlockFragment on DatoCmsImageSliderModularBlock {
    id
    model {
      apiKey
    }
    images {
      ...ImageSliderImagesFragment
    }
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment QuoteModularBlockFragment on DatoCmsQuoteModularBlock {
    id
    model {
      apiKey
    }
    quote
  }

  fragment StatisticsModularBlockFragment on DatoCmsStatisticsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      statistics {
        id
        heading
        text
      }
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TeamProfilesModularBlockFragment on DatoCmsTeamProfilesModularBlock {
    id
    model {
      apiKey
    }
    heading
    teamProfiles {
      id
      image {
        gatsbyImageData(width: 330, height: 450)
        alt
      }
      name
      role
      bio
    }
  }

  fragment TestimonialsModularBlockFragment on DatoCmsTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      testimonials {
        id
        image {
          gatsbyImageData(width: 420, height: 420)
          alt
        }
        quote
        name
        role
      }
    }
  }
`;
