import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandFonts,
  fontSize,
} from '../styles';
import { Container, Button } from './ui';
import ContactForm from './ContactForm';

const StyledContactFormInformation = styled.section`
  ${sectionMargins(undefined, '100px')};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 350px;
    gap: 15px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1fr 390px;
  `}
`;

const StyledImageInformation = styled.div`
  position: relative;

  ${maxBreakpointQuery.smedium`
    margin-right: -20px;
    margin-left: -20px;
  `}

  ${minBreakpointQuery.smedium`
    grid-row: 1;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  min-height: 500px;
`;

const StyledInformation = styled.div`
  position: absolute;
  left: 20px;
  padding: 15px;
  background-color: ${standardColours.white};

  ${maxBreakpointQuery.smedium`
    top: 20px;
  `}

  ${minBreakpointQuery.smedium`
    bottom: 20px;
  `}
`;

const StyledText = styled.p`
  ${fontSize(18)};
  line-height: 1.4;
  white-space: break-spaces;

  span {
    ${brandFonts.futuraPTDemi()};
  }
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const ContactFormInformation = ({
  id,
  heading,
  text,
  image: { gatsbyImageData, alt },
  altTheme,
}) => {
  const {
    datoCmsContactInformation: { address, phoneNumber },
  } = useStaticQuery(graphql`
    query ContactFormInformationQuery {
      datoCmsContactInformation {
        address
        phoneNumber
      }
    }
  `);

  return (
    <StyledContactFormInformation id={id}>
      <Container>
        <StyledInner>
          <ContactForm heading={heading} text={text} altTheme={altTheme} />
          <StyledImageInformation>
            <StyledImage image={gatsbyImageData} alt={alt} />
            {(address || phoneNumber) && (
              <StyledInformation>
                <StyledText>
                  <span>Milexa Group</span>
                </StyledText>
                {address && <StyledText>{address}</StyledText>}
                {phoneNumber && (
                  <StyledText>
                    <span>{phoneNumber}</span>
                  </StyledText>
                )}
                {address && (
                  <StyledButton
                    href={`https://www.google.com/maps/place/${address}/`}
                    target="_blank"
                    rel="noopener"
                  >
                    Open in maps
                  </StyledButton>
                )}
              </StyledInformation>
            )}
          </StyledImageInformation>
        </StyledInner>
      </Container>
    </StyledContactFormInformation>
  );
};

export default ContactFormInformation;

export const ContactFormInformationImageFragment = graphql`
  fragment ContactFormInformationImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 800, height: 540)
    alt
  }
`;
