import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  standardColours,
} from '../styles';
import { Container, Button } from './ui';
import ImageCarousel from './ImageCarousel';

const StyledBrands = styled.section`
  ${sectionMargins(undefined, '80px')};
`;

const StyledItems = styled.div``;

const StyledItem = styled.article`
  text-align: center;
`;

const StyledHeader = styled.header`
  padding: 60px 0;
  background-color: ${({ altTheme }) =>
    altTheme ? brandColours.slate[600] : standardColours.white};

  ${minBreakpointQuery.tiny`
    padding-top: 70px;
    padding-bottom: 70px;
  `}

  ${minBreakpointQuery.small`
    padding-top: 80px;
    padding-bottom: 80px;
  `}

  ${minBreakpointQuery.medium`
    padding-top: 90px;
    padding-bottom: 90px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 100px;
    padding-bottom: 100px;
  `}
`;

const StyledLogo = styled.img`
  max-height: 30px;
  max-width: 230px;

  ${minBreakpointQuery.tiny`
    max-height: 36px;
    max-width: 260px;
  `}

  ${minBreakpointQuery.small`
    max-height: 42px;
    max-width: 290px;
  `}

  ${minBreakpointQuery.medium`
    max-height: 48px;
    max-width: 320px;
  `}

  ${minBreakpointQuery.large`
    max-height: 54px;
    max-width: 350px;
  `}
`;

const StyledText = styled.p`
  margin: 14px auto 0;
  max-width: 600px;
  ${fontSize(18)};

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}

  ${minBreakpointQuery.small`
    margin-top: 22px;
    ${fontSize(21)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    ${fontSize(24)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 26px;

  ${minBreakpointQuery.small`
    margin-top: 36px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 46px;
  `}
`;

const StyledImageCarousel = styled(ImageCarousel)`
  padding: 0;
`;

const Brands = ({ items }) => (
  <StyledBrands>
    <StyledItems>
      {items.map(
        ({ name, lightLogo, darkLogo, websiteUrl, description, images }, i) => {
          const altTheme = i % 2;
          const logo = altTheme ? lightLogo : darkLogo;

          return (
            <StyledItem>
              <StyledHeader altTheme={altTheme}>
                <Container>
                  <StyledLogo src={logo.url} alt={logo.alt} loading="lazy" />
                  {description && (
                    <StyledText altTheme={altTheme}>{description}</StyledText>
                  )}
                  <StyledButton
                    href={websiteUrl}
                    target="_blank"
                    rel="noopener"
                    alt={altTheme}
                  >
                    Visit {name}
                  </StyledButton>
                </Container>
              </StyledHeader>
              <StyledImageCarousel
                items={images}
                backgroundColours={{
                  top: altTheme
                    ? brandColours.slate[600]
                    : standardColours.white,
                  bottom: altTheme
                    ? standardColours.transparent
                    : brandColours.slate[600],
                }}
                altTheme={!altTheme}
              />
            </StyledItem>
          );
        }
      )}
    </StyledItems>
  </StyledBrands>
);

export default Brands;
