import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
} from '../styles';

const StyledTestimonialCard = styled.figure`
  position: relative;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${standardColours.white};
  background-color: ${brandColours.slate[500]};

  ${minBreakpointQuery.large`
    padding: 25px;
  `}
`;

const StyledBackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      ${standardColours.transparent} 0%,
      ${standardColours.black} 100%
    );
  }
`;

const StyledContent = styled.div`
  position: relative;
  padding-top: 120px;

  ${minBreakpointQuery.small`
    padding-top: 140px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 160px;
  `}
  
  ${minBreakpointQuery.xlarge`
    padding-top: 180px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 200px;
  `}
`;

const StyledTestimonial = styled.blockquote`
  margin: 0;
`;

const StyledBackgroundImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledQuote = styled.p``;

const StyledNameRole = styled.figcaption`
  margin-top: 10px;

  span {
    ${brandFonts.futuraPTDemi()};
  }
`;

const TestimonialCard = ({ image, quote, name, role, ...props }) =>
  quote && (
    <StyledTestimonialCard {...props}>
      {image && (
        <StyledBackgroundImageWrapper>
          <StyledBackgroundImage
            image={image.gatsbyImageData}
            alt={image.alt}
          />
        </StyledBackgroundImageWrapper>
      )}
      <StyledContent>
        <StyledTestimonial>
          <StyledQuote>"{quote}"</StyledQuote>
        </StyledTestimonial>
        {name && (
          <StyledNameRole>
            <span>{name}</span>
            {role && `, ${role}`}
          </StyledNameRole>
        )}
      </StyledContent>
    </StyledTestimonialCard>
  );

export default TestimonialCard;
