import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandFonts,
  fontSize,
} from '../styles';
import { Container } from './ui';
import ImageContentCard from './ImageContentCard';

const StyledImageContentCards = styled.section`
  ${({ altTheme, isHomePage }) => {
    if (altTheme && isHomePage) {
      return css`
        margin: 10px 0;
      `;
    } else {
      return css`
        ${sectionMargins(undefined, '80px')};
      `;
    }
  }}

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled.h2`
  margin-bottom: 25px;
  ${brandFonts.futuraPTDemi()};
  ${fontSize(26)};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 45px;
    ${fontSize(30)};
  `}
`;

const StyledItems = styled.div`
  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
  `}
`;

const ImageContentCards = ({ heading, items, altTheme, isHomePage }) => (
  <StyledImageContentCards altTheme={altTheme} isHomePage={isHomePage}>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ id, image, heading, text }) => (
          <ImageContentCard
            key={id}
            image={image}
            heading={heading}
            text={text}
          />
        ))}
      </StyledItems>
    </Container>
  </StyledImageContentCards>
);

export default ImageContentCards;
