import React from 'react';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  fluidFontSize,
  fontSize,
  breakpointSizes,
  standardColours,
  brandColours,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { Container } from './ui';

const StyledContentCards = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  text-align: center;
  ${fluidFontSize(
    '35px',
    '50px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }};
`;

const StyledCardsWrap = styled.div`
  ${maxBreakpointQuery.smedium`
    position: relative;
    overflow: auto;
    margin-left: -20px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 10px;
    }

    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        ${standardColours.transparent} 0%,
        ${brandColours.clay[100]} 100%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        ${standardColours.transparent} 0%,
        ${brandColours.clay[100]} 100%
      );
    }
  `};
`;

const StyledCards = styled.div`
  display: flex;
  padding: 25px 20px;
  width: 100%;

  ${maxBreakpointQuery.smedium`
    justify-content: space-between;
    overflow: auto;
    scroll-snap-type: x mandatory;
  `};

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1085px;
    margin: 0 auto;
  `};
`;

const StyledCard = styled.div`
  padding: 20px 15px;
  background-color: ${standardColours.white};

  ${maxBreakpointQuery.smedium`
    margin-right: 15px;
    min-width: 300px;
    scroll-snap-align: center;
  `};

  ${minBreakpointQuery.smedium`
    width: 100%;
    max-width: 335px;
  `}
`;

const StyledCardHeading = styled.h3`
  ${fontSize(25)};
  margin-bottom: 5px;
`;

const StyledCardContent = styled.p`
  line-height: 1.4;

  ${minBreakpointQuery.medium`
    ${fontSize(18)};
  `};

  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
  `};
`;

const ContentCards = ({ heading, items, altTheme }) => (
  <StyledContentCards>
    <Container>
      <StyledHeading altTheme={altTheme}>{heading}</StyledHeading>
      <StyledCardsWrap>
        <StyledCards>
          {items.map(({ id, heading, content }) => (
            <StyledCard key={id}>
              <StyledCardHeading>{heading}</StyledCardHeading>
              <StyledCardContent>{content}</StyledCardContent>
            </StyledCard>
          ))}
        </StyledCards>
      </StyledCardsWrap>
    </Container>
  </StyledContentCards>
);

export default ContentCards;
