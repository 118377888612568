import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  fontSize,
} from '../styles';
import { Container } from './ui';
import Testimonials from './Testimonials';

const StyledImageTestimonials = styled.section`
  ${sectionMargins(undefined, '60px')};

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled.h2`
  margin-bottom: 30px;
  ${fontSize(26)};
  text-align: center;

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
  `}
`;

const StyledImage = styled.img`
  padding: 0 20px;
  width: 100%;

  ${minBreakpointQuery.medium`
    padding-right: 30px;
    padding-left: 30px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    padding-left: 40px;
  `}
`;

const StyledTestimonials = styled(Testimonials)`
  padding: 0 20px;

  ${maxBreakpointQuery.smedium`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.smedium`
    .slick-arrow {
      
      &:before {
        ${({ altTheme }) => {
          if (altTheme) {
            return css`
              border-color: ${standardColours.white};
            `;
          }
        }}
      }

      &.slick-prev {
        left: 40%;
      }

      &.slick-next {
        right: 40%;
      }
    }
  `}

  ${minBreakpointQuery.medium`
    padding-right: 30px;
    padding-left: 30px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    padding-left: 40px;
  `}
`;

const ImageTestimonials = ({
  heading,
  image: { url, alt },
  items,
  altTheme,
}) => (
  <StyledImageTestimonials altTheme={altTheme}>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledInner>
        <StyledImage src={url} alt={alt} loading="lazy" />
        <StyledTestimonials
          items={items}
          altTheme={altTheme}
          isImageTestimonials={true}
        />
      </StyledInner>
    </Container>
  </StyledImageTestimonials>
);

export default ImageTestimonials;
