import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins, brandColours } from '../styles';
import { Container } from './ui';
import TestimonialCard from './TestimonialCard';

const StyledFeaturedInformationCards = styled.section`
  ${sectionMargins(undefined, '60px')};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const commonCardStyles = () => {
  return css`
    padding: 20px;
    min-height: 200px;
  `;
};

const StyledTestimonialCard = styled(TestimonialCard)`
  ${commonCardStyles()};
`;

const StyledContentCard = styled.article`
  ${commonCardStyles()};
  background-color: ${brandColours.olive[200]};
`;

const StyledHeading = styled.h2``;

const StyledText = styled.p`
  margin-top: 12px;
  white-space: break-spaces;

  ${minBreakpointQuery.small`
    margin-top: 15px;
  `}
`;

const FeaturedInformationCards = ({ testimonial, content }) => (
  <StyledFeaturedInformationCards>
    <Container>
      <StyledInner>
        <StyledTestimonialCard
          image={testimonial.image}
          quote={testimonial.quote}
          name={testimonial.name}
          role={testimonial.role}
        />
        <StyledContentCard>
          <StyledHeading>{content.heading}</StyledHeading>
          <StyledText>{content.text}</StyledText>
        </StyledContentCard>
      </StyledInner>
    </Container>
  </StyledFeaturedInformationCards>
);

export default FeaturedInformationCards;
