import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { Container, Heading } from './ui';

const StyledTeamProfiles = styled.section`
  ${sectionMargins()};

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  text-align: center;
`;

const StyledItems = styled.div`
  ${minBreakpointQuery.smedium`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 7.5px;
    margin-left: 7.5px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-right: 12.5px;
    margin-left: 12.5px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 25px;
    margin-left: 25px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-right: 30px;
    margin-left: 30px;
  `}
`;

const StyledItem = styled.article`
  display: grid;
  gap: 10px;
  margin: 20px 0;
  padding: 15px;
  background-color: ${brandColours.slate[500]};

  ${minBreakpointQuery.tiny`
    grid-template-columns: 1fr 1.3fr;
    align-items: center;
  `}

  ${minBreakpointQuery.smedium`
    margin: 7.5px;
    width: calc(50% - 15px);
  `}

  ${minBreakpointQuery.mlarge`
    margin: 12.5px;
    width: calc(50% - 25px);
  `}
`;

const StyledImage = styled(GatsbyImage)`
  filter: grayscale(100%);

  ${maxBreakpointQuery.tiny`
    aspect-ratio: 1 / 1;
  `}

  ${minBreakpointQuery.tiny`
    height: 100%;
  `}
`;

const StyledContent = styled.div`
  padding-bottom: 10px;
  color: ${standardColours.white};

  ${minBreakpointQuery.tiny`
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  `}
`;

const StyledName = styled.h3`
  color: ${brandColours.clay[200]};
  ${fontSize(20)};
`;

const StyledRole = styled.p`
  margin: 4px 0 14px;
`;

const StyledBio = styled.p`
  white-space: break-spaces;
`;

const TeamProfiles = ({ heading, items, altTheme }) => (
  <StyledTeamProfiles altTheme={altTheme}>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(
          ({ id, image: { gatsbyImageData, alt }, name, role, bio }) => (
            <StyledItem key={id}>
              <StyledImage image={gatsbyImageData} alt={alt} />
              <StyledContent>
                <StyledName>{name}</StyledName>
                <StyledRole>{role}</StyledRole>
                {bio && <StyledBio>{bio}</StyledBio>}
              </StyledContent>
            </StyledItem>
          )
        )}
      </StyledItems>
    </Container>
  </StyledTeamProfiles>
);

export default TeamProfiles;
