import React from 'react';
import styled from 'styled-components';
import { sectionPaddings, brandColours, standardColours } from '../styles';
import { Container } from './ui';

const StyledFeaturedLogos = styled.section`
  ${sectionPaddings('80px', '250px')};
  background-color: ${brandColours.olive['200']};
`;

const StyledItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${standardColours.white};
  height: 157px;
  width: 296px;
  margin: 10px;
  border-radius: 7px;
`;

const StyledImage = styled.img`
  max-height: 157px;
  max-width: 100%;
`;

const FeaturedLogos = ({ items }) => {
  return (
    <StyledFeaturedLogos>
      <Container>
        <StyledItems>
          {items.map(({ url, alt }, i) => (
            <StyledImageContainer>
              <StyledImage
                key={`logo-${i}`}
                src={url}
                alt={alt}
                loading="lazy"
              />
            </StyledImageContainer>
          ))}
        </StyledItems>
      </Container>
    </StyledFeaturedLogos>
  );
};

export default FeaturedLogos;
