import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  brandColours,
  brandFonts,
  fluidFontSize,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import StatisticCard from './StatisticCard';
import map from '../images/map.svg';

const StyledStatistics = styled.section`
  position: relative;
  ${({ isHomePage }) =>
    isHomePage ? sectionPaddings('60px') : sectionMargins('60px')};
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledSvg = styled(Svg)`
  ${maxBreakpointQuery.large`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    top: -40px;
    right: 0;
    height: 308px;
    width: 480px;
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: ${({ isHomePage }) => (isHomePage ? '70' : '25')}px;
  color: ${brandColours.clay[300]};
  ${brandFonts.futuraPTBook()};
  ${fluidFontSize(
    '22px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: ${({ isHomePage }) => (isHomePage ? '90' : '35')}px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: ${({ isHomePage }) => (isHomePage ? '110' : '45')}px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 15px;

  ${minBreakpointQuery.tiny`
    gap: 25px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    gap: 35px;
  `}
`;

const Statistics = ({ heading, items, isHomePage }) => (
  <StyledStatistics isHomePage={isHomePage}>
    {isHomePage && <StyledSvg image={map} />}
    <StyledContainer>
      <StyledHeading isHomePage={isHomePage}>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ id, heading, text }) => (
          <StatisticCard key={id} heading={heading} text={text} />
        ))}
      </StyledItems>
    </StyledContainer>
  </StyledStatistics>
);

export default Statistics;
