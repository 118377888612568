import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import { Container, Slider } from './ui';

const StyledImageCarousel = styled.section`
  ${sectionPaddings(undefined, '60px')};
  overflow: hidden;

  ${({ backgroundColours }) => {
    if (backgroundColours.top || backgroundColours.bottom) {
      return css`
        background: linear-gradient(
          to bottom,
          ${backgroundColours.top
              ? backgroundColours.top
              : standardColours.transparent}
            45%,
          ${backgroundColours.bottom
              ? backgroundColours.bottom
              : standardColours.transparent}
            45%
        );
      `;
    }
  }}
`;

const StyledSlider = styled(Slider)`

  ${minBreakpointQuery.tsmall`
    padding-bottom: 60px;
  `}

  .slick-arrow {

    &:before {

      ${({ altTheme }) => {
        if (altTheme) {
          return css`
            border-color: ${standardColours.white};
          `;
        }
      }}
    }
  }

  .slick-list {
    overflow: visible;
    margin: 0 15px;

    ${minBreakpointQuery.tiny`
      margin-right: 30px;
      margin-left: 30px;
    `}

    ${minBreakpointQuery.tsmall`
      margin-right: 0;
      margin-left: 0;
    `}

    ${minBreakpointQuery.small`
      margin-right: 15px;
      margin-left: 15px;
    `}
  }

  .slick-slide {
    padding: 0 7.5px;

    ${minBreakpointQuery.small`
      padding-right: 10px;
      padding-left: 10px;
    `}

    ${minBreakpointQuery.medium`
      padding-right: 12.5px;
      padding-left: 12.5px;
    `}

    ${minBreakpointQuery.large`
      padding-right: 15px;
      padding-left: 15px;
    `}
  }
`;

const ImageCarousel = ({ items, backgroundColours, altTheme, ...props }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    infinite: true,
    responsive: [
      {
        breakpoint: 979,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 519,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledImageCarousel backgroundColours={backgroundColours} {...props}>
      <Container wide={true}>
        <StyledSlider
          numSlides={items.length}
          {...sliderOptions}
          altTheme={altTheme}
        >
          {items.map(({ gatsbyImageData, alt }) => (
            <GatsbyImage image={gatsbyImageData} alt={alt} />
          ))}
        </StyledSlider>
      </Container>
    </StyledImageCarousel>
  );
};

export default ImageCarousel;

export const ImageCarouselImagesFragment = graphql`
  fragment ImageCarouselImagesFragment on DatoCmsFileField {
    gatsbyImageData(width: 440, height: 700)
    alt
  }
`;
