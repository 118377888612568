import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  sectionPaddings,
  brandColours,
  fluidFontSize,
} from '../styles';
import { Container } from './ui';

const StyledQuote = styled.section`
  ${sectionPaddings('160px', '280px')};
  background-color: ${brandColours.olive[200]};
`;

const StyledBlockquote = styled.blockquote`
  margin: auto;
  max-width: 1000px;
  text-align: center;
`;

const StyledText = styled.p`
  ${fluidFontSize(
    '22px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
`;

const Quote = ({ text }) => (
  <StyledQuote>
    <Container>
      <StyledBlockquote>
        <StyledText>{text}</StyledText>
      </StyledBlockquote>
    </Container>
  </StyledQuote>
);

export default Quote;
