import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';

const StyledCtaCards = styled.section`
  ${sectionMargins(undefined, '100px')};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  `}
`;

const CtaCards = ({ children }) =>
  children && (
    <StyledCtaCards>
      <Container>
        <StyledItems>{children}</StyledItems>
      </Container>
    </StyledCtaCards>
  );

export default CtaCards;
