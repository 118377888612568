import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
} from '../styles';
import { Link, ArrowIcon } from './ui';
import { buildUrl } from '../utils';

const StyledCtaCard = styled.article`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  color: ${standardColours.white};
  background-color: ${brandColours.slate[500]};
  cursor: pointer;
`;

const StyledHeading = styled.h2`
  ${fontSize(24)};

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  margin: 10px 0;
  ${fontSize(18)};

  ${minBreakpointQuery.large`
    margin-top: 12px;
    margin-bottom: 12px;
    ${fontSize(20)};
  `}
`;

const StyledLink = styled(Link)`
  display: block;
  align-self: flex-end;
  position: relative;
  margin-top: auto;
  padding-right: 28px;
  ${brandFonts.futuraPTDemi()};
  ${fontSize(14)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledIcon = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  right: 0;
  fill: ${brandColours.clay[300]};
  transform: translateY(-50%);
`;

const CtaCard = ({ heading, text, link }) => (
  <StyledCtaCard
    onClick={() => {
      navigate(buildUrl(link.slug, link.data));
    }}
  >
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
    <StyledLink to={link.slug} linkData={link.data}>
      {link.text}
      <StyledIcon />
    </StyledLink>
  </StyledCtaCard>
);

export default CtaCard;
