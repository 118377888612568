import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Slider } from './ui';

const StyledTestimonials = styled.section`
  ${({ isImageTestimonials }) => {
    if (!isImageTestimonials) {
      return css`
        ${sectionMargins('50px', '160px')};
      `;
    }
  }}
`;

const commonItemsStyles = () => {
  return css`
    ${({ altTheme }) => {
      if (altTheme) {
        return css`
          color: ${standardColours.white};

          .slick-arrow {
            &:before {
              border-color: ${standardColours.white};
            }
          }
        `;
      }
    }};

    ${({ isImageTestimonials }) => {
      if (!isImageTestimonials) {
        return css`
          margin: 0 10px;

          ${minBreakpointQuery.tiny`
            margin-right: 15px;
            margin-left: 15px;
          `}

          ${minBreakpointQuery.medium`
            margin-right: 20px;
            margin-left: 20px;
          `}

          ${minBreakpointQuery.large`
            margin-right: 25px;
            margin-left: 25px;
          `}
        `;
      }
    }}
  `;
};

const StyledItems = styled.div`
  ${commonItemsStyles()};
`;

const StyledItemsSlider = styled(Slider)`
  ${commonItemsStyles()};
  padding-bottom: 40px;
`;

const StyledItem = styled.figure`
  margin: 0;

  ${maxBreakpointQuery.smedium`
    text-align: center;
  `}
`;

const StyledInner = styled.div`
  ${({ hasImage }) => {
    if (hasImage) {
      return css`
        display: grid;
        gap: 15px;

        ${minBreakpointQuery.tiny`
          gap: 25px;
        `}

        ${minBreakpointQuery.smedium`
          grid-template-columns: 1fr 1.62fr;
          align-items: center;
        `}

        ${minBreakpointQuery.medium`
          gap: 35px;
        `}

        ${minBreakpointQuery.large`
          gap: 45px;
        `}
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;

  ${maxBreakpointQuery.smedium`
    margin: auto;
    width: 200px;
  `}
`;

const StyledContent = styled.div``;

const StyledTestimonial = styled.blockquote`
  margin: 0;
`;

const StyledQuote = styled.p`
  ${fluidFontSize(
    '22px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledNameRole = styled.figcaption`
  margin-top: 8px;
  color: ${brandColours.clay[500]};
  ${brandFonts.futuraPTDemi()};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const Testimonials = ({ items, altTheme, isImageTestimonials, ...props }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true,
  };

  const ItemsComponent = items.length > 1 ? StyledItemsSlider : StyledItems;

  const ItemsCode = () => (
    <ItemsComponent
      altTheme={altTheme}
      isImageTestimonials={isImageTestimonials}
      {...sliderOptions}
    >
      {items.map(({ id, image, quote, name, role }) => (
        <StyledItem key={id}>
          <StyledInner hasImage={image}>
            {image && (
              <StyledImage image={image.gatsbyImageData} alt={image.alt} />
            )}
            <StyledContent>
              <StyledTestimonial>
                <StyledQuote>"{quote}"</StyledQuote>
              </StyledTestimonial>
              <StyledNameRole>
                {name}, {role}
              </StyledNameRole>
            </StyledContent>
          </StyledInner>
        </StyledItem>
      ))}
    </ItemsComponent>
  );

  return (
    <StyledTestimonials isImageTestimonials={isImageTestimonials} {...props}>
      {isImageTestimonials ? (
        <ItemsCode />
      ) : (
        <Container>
          <ItemsCode />
        </Container>
      )}
    </StyledTestimonials>
  );
};

export default Testimonials;
