import React from 'react';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  fontSize,
  standardColours,
  brandColours,
  minBreakpointQuery,
  zIndexLayers,
} from '../styles';
import { Container, Slider } from './ui';

const StyledContentCardsCarousel = styled.section`
  ${sectionMargins('30px', '80px')};
`;

const StyledHeading = styled.h2`
  ${fontSize(35)};
  text-align: center;

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }};
`;

const StyledCards = styled(Slider)`
  margin-top: 35px;
  padding-bottom: 60px;

  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  ${minBreakpointQuery.smedium`
  .slick-list{
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 50px;
      z-index: ${zIndexLayers.fifth};
    }
    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        ${standardColours.transparent} 0%,
        ${brandColours.clay[100]} 100%
      );
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        ${standardColours.transparent} 0%,
        ${brandColours.clay[100]} 100%
      );
    }
  }
  `}
`;

const StyledCard = styled.div`
  background-color: ${standardColours.white};
  padding: 20px;
  max-width: 310px;
  margin: 0 10px;
  ${fontSize(14)}
`;

const StyledCardHeading = styled.h3`
  ${fontSize(20)};
`;

const StyledCardContent = styled.p`
  margin: 10px 0 15px;
`;

const StyledCardLabel = styled.p`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 6px;
  background-color: ${({ label }) =>
    label === 'Career'
      ? brandColours.olive[100]
      : label === 'Perk'
      ? brandColours.slate[100]
      : brandColours.clay[200]};
`;

const ContentCardsCarousel = ({ heading, items, altTheme }) => {
  const sliderOptions = {
    centerMode: true,
    variableWidth: true,
  };

  return (
    <StyledContentCardsCarousel>
      <Container>
        <StyledHeading altTheme={altTheme}>{heading}</StyledHeading>
        <StyledCards {...sliderOptions}>
          {items.map(({ id, heading, content, label }) => (
            <StyledCard key={id}>
              <StyledCardHeading>{heading}</StyledCardHeading>
              <StyledCardContent>{content}</StyledCardContent>
              {label && (
                <StyledCardLabel label={label}>{label}</StyledCardLabel>
              )}
            </StyledCard>
          ))}
        </StyledCards>
      </Container>
    </StyledContentCardsCarousel>
  );
};

export default ContentCardsCarousel;
