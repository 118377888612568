import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  standardColours,
  fontSize,
  visuallyHidden,
  brandFonts,
} from '../styles';
import { Heading } from './ui';

const StyledContactForm = styled.section`
  padding: 20px;
  background-color: ${({ altTheme }) =>
    altTheme ? brandColours.slate[500] : standardColours.white};

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled(Heading)``;

const StyledText = styled.p`
  margin-top: 8px;
  ${fontSize(18)};
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 20px;
`;

const StyledLabel = styled.label`
  ${({ honeypot, full }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()};
      `;
    } else if (full) {
      return css`
        grid-column: 1 / 3;
      `;
    }
  }}
`;

const StyledLabelText = styled.span`
  display: block;
  margin-bottom: 4px;
  ${fontSize(14)};
`;

const commonFieldStyles = altTheme => {
  return css`
    padding: 10px;
    width: 100%;
    color: ${altTheme ? standardColours.white : 'inherit'};
    ${brandFonts.futuraPTBook()};
    ${fontSize(14)};
    background-color: ${altTheme
      ? brandColours.slate[600]
      : brandColours.slate[100]};
    border: none;
  `;
};

const StyledInput = styled.input`
  ${({ altTheme }) => commonFieldStyles(altTheme)};
  height: 42px;
`;

const StyledTextarea = styled.textarea`
  ${({ altTheme }) => commonFieldStyles(altTheme)};
  height: 146px;
  resize: none;
`;

const StyledSubmit = styled.button`
  grid-column: 1 / 3;
  height: 50px;
  width: 100%;
  ${brandFonts.futuraPTDemi()};
  ${fontSize(18)};
  border: none;
  background-color: ${({ altTheme }) =>
    altTheme ? standardColours.white : brandColours.slate[500]};

  ${({ altTheme }) => {
    if (!altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const ContactForm = ({ heading, text, altTheme }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('contact-form');
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <StyledContactForm altTheme={altTheme}>
      <StyledHeading>{heading}</StyledHeading>
      {!formSubmitted ? (
        <>
          {text && <StyledText>{text}</StyledText>}
          <StyledForm
            id="contact-form"
            name="contact-form"
            method="POST"
            netlify-honeypot="question"
            data-netlify="true"
            onSubmit={e => handleSubmit(e)}
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <StyledLabel honeypot={true}>
              <StyledLabelText>Question</StyledLabelText>
              <StyledInput type="text" name="question" altTheme={altTheme} />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>First Name</StyledLabelText>
              <StyledInput
                type="text"
                name="first-name"
                required
                altTheme={altTheme}
              />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Last Name</StyledLabelText>
              <StyledInput type="text" name="last-name" altTheme={altTheme} />
            </StyledLabel>
            <StyledLabel full={true}>
              <StyledLabelText>Email Address</StyledLabelText>
              <StyledInput
                type="email"
                name="email"
                required
                altTheme={altTheme}
              />
            </StyledLabel>
            <StyledLabel full={true}>
              <StyledLabelText>Message</StyledLabelText>
              <StyledTextarea name="message" required altTheme={altTheme} />
            </StyledLabel>
            <StyledSubmit type="submit" altTheme={altTheme}>
              Send message
            </StyledSubmit>
          </StyledForm>
        </>
      ) : (
        <StyledText>
          Thank you, your message has been sent and someone will get back to you
          as quick as they can.
        </StyledText>
      )}
    </StyledContactForm>
  );
};

export default ContactForm;
