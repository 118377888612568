import React from 'react';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  brandColours,
  brandFonts,
  fluidFontSize,
  standardColours,
} from '../styles';
import { Container, Button, Overline } from './ui';

const StyledCtaStrip = styled.section`
  text-align: center;

  ${({ backgroundColour }) => {
    if (backgroundColour) {
      return css`
        position: relative;
        z-index: 0;
        ${sectionPaddings('50px', '130px')};
        background-color: ${backgroundColour.hex};
      `;
    } else {
      return css`
        ${sectionMargins('50px', '130px')};
      `;
    }
  }}

  ${({ backgroundColour }) => {
    if (
      (backgroundColour && backgroundColour.hex.toLowerCase()) ===
      brandColours.clay[100].toLowerCase()
    ) {
      return css`
        ${sectionMargins('50px', '130px')};
      `;
    }
  }}

  ${({ backgroundColour, altTheme }) => {
    if (!backgroundColour && altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled.h2`
  margin: auto;
  max-width: 1000px;
  ${brandFonts.quartoBlack};
  ${fluidFontSize(
    '32px',
    '60px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.2;
  margin-bottom: 40px;

  strong {
    position: relative;
    display: inline-block;
    font-weight: inherit;

    &:before {
      content: '';
      position: absolute;
      right: -3px;
      bottom: -3px;
      left: -3px;
      z-index: -1;
      height: 15px;
      background-color: ${brandColours.clay[300]};
      opacity: 0.3;

      ${minBreakpointQuery.small`
        height: 20px;
      `}

      ${minBreakpointQuery.medium`
        bottom: -5px;
        right: -5px;
        left: -5px;
        height: 25px;
      `}

      ${minBreakpointQuery.large`
        height: 30px;
      `}
    }
  } 
`;

const CtaStrip = ({ overline, heading, link, backgroundColour, altTheme }) => {
  return (
    <StyledCtaStrip backgroundColour={backgroundColour} altTheme={altTheme}>
      <Container>
        {overline && <Overline>{overline}</Overline>}
        <StyledHeading
          dangerouslySetInnerHTML={{
            __html: heading.replace(/(<p>|<\/p>)/g, ''),
          }}
        />
        <Button to={link.page.slug} linkData={link.page}>
          {link.text}
        </Button>
      </Container>
    </StyledCtaStrip>
  );
};

export default CtaStrip;
